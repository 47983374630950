<template>
  <div class="ai-dialogue-box">
    <div class="ai-dialogue-title">
      <div class="ai-dialogue-left-line">
        {{
          mark == "sd"
            ? "水稻"
            : mark == "dd"
            ? "大豆"
            : mark == "xm"
            ? "小麦"
            : mark == "dp"
            ? "大棚"
            : ""
        }}病虫害防治
      </div>
    </div>
    <div class="illness-list">
      <div
        :class="['items-illness', illnessIndex == index ? 'active' : '']"
        v-for="(item, index) in illnessList[mark]"
        :key="index"
        @click="sendSparkQA(item, index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="chat-window">
      <div class="chat-main">
        <div class="chat-content">
          <div
            :class="[
              'chat-content-item',
              item.type == 'query' ? 'is-user-style' : '',
            ]"
            v-for="(item, index) in contentList"
            :key="index"
          >
            <div class="user-send-content">{{ item.value }}</div>
          </div>
        </div>
        <img
          class="dialogue-load"
          src="@/assets/image/ai/dialogue/dialogue_load.gif"
          alt=""
          v-show="isLoading"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getSparkQAApi } from "@/request/api.js";
export default {
  name: "aiDialogue",
  props: {
    mark: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      setInt: null,
      isLoading: false,
      illnessIndex: -1,
      illnessList: {
        sd: [
          "稻瘟病",
          "白叶枯",
          "稻飞虱",
          "水稻螟虫",
          "稻纵卷叶螟",
          "水稻纹枯病",
          "稻曲病",
          "水稻细菌性条斑病",
        ],
        dd: [
          "豆天蛾虫",
          "造桥虫",
          "象鼻虫",
          "食心虫",
          "大豆蚜虫",
          "根腐病",
          "大豆灰斑病",
          "大豆炭疽病",
          "大豆紫斑病",
          "大豆病毒病",
        ],
        dp: [
          "白粉虱",
          "蚜虫",
          "红蜘蛛",
          "茶黄螨",
          "蓟马",
          "潜叶蝇",
          "蝗虫",
          "立枯病",
          "霜霉病",
          "灰霉病",
        ],
        xm: [
          "赤霉病",
          "白粉病",
          "小麦锈病",
          "小麦叶枯病",
          "小麦纹枯病",
          "小麦黑穗病",
          "小麦蚜虫",
          "小麦吸浆虫",
          "小麦根腐病",
          "红蜘蛛",
        ],
      },
      contentList: [],
    };
  },
  watch: {
    contentList: {
      handler() {
        this.scrollTimeout = setTimeout(() => {
          let divArr = document.querySelectorAll(".chat-content-item");
          let lastDiv = divArr[divArr.length - 1];
          if (lastDiv) {
            lastDiv.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        }, 200);
      },
      deep: true,
    },
  },
  destroyed() {
    if (this.setInt) {
      clearInterval(this.setInt);
      this.setInt = null;
    }
  },
  methods: {
    async sendSparkQA(name, index) {
      this.isLoading = true;
      this.illnessIndex = index;
      let obj = { value: "", type: "query" };
      obj.value = name;
      this.contentList.push(obj);
      let title =
        this.mark == "sd"
          ? "水稻"
          : this.mark == "dd"
          ? "大豆"
          : this.mark == "xm"
          ? "小麦"
          : this.mark == "dp"
          ? "大棚"
          : "";
      let { code, results } = await getSparkQAApi({
        message: `${title}的${name}的产生原因和具体的防治措施`,
      });
      if (code != 0) return;
      this.isLoading = false;
      this.handleMsg(results);
    },
    handleMsg(msg) {
      clearInterval(this.setInt);
      let introduce = document.querySelectorAll(".chat-content")[0];
      let newDiv = document.createElement("div");
      let content = document.createElement("div");
      newDiv.classList.add("chat-content-item");
      newDiv.classList.add("query");
      content.classList.add("user-send-content");
      newDiv.style.display = "flex";
      newDiv.style.marginBottom = "20px";
      content.style.width = "970px";
      content.style.display = "flex";
      content.style.color = "#fff";
      content.style.padding = "20px 29px";
      content.style.fontSize = "14px";
      content.style.display = "flex";
      content.style.flexDirection = "column";
      content.style.borderRadius = "10px";
      content.style.background = "rgba(34, 77, 127, 0.7)";

      newDiv.appendChild(content);
      introduce.appendChild(newDiv);
      let i = 0,
        str = msg;
      function typing() {
        if (i < str.length) {
          let char = str.charAt(i++);
          content.innerHTML += char;
        }
      }
      this.setInt = setInterval(typing, 100);
    },
  },
};
</script>

<style lang="less" scoped>
.ai-dialogue-box {
  height: 100%;
  background: #0e2138;
  padding: 53px 65px;
  .ai-dialogue-title {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    padding-right: 30px;
    align-items: center;
    justify-content: space-between;

    .ai-dialogue-left-line {
      font-size: 18px;
      font-weight: 400;
      color: #fff;
      padding: 0 0 0 15px;
      position: relative;

      &::before {
        content: "";
        background-color: #3e90e5;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
  .illness-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .items-illness {
      height: 32px;
      line-height: 32px;
      background: #173355;
      padding: 0 17px;
      text-align: center;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #4992ff;
      cursor: pointer;
      margin: 0 31px 33px 0;
      &.active {
        background: #4992ff;
        color: #fff;
      }
    }
  }
  .chat-window {
    height: 80%;
    background: #0e2138;
    display: flex;
    justify-content: center;
    padding: 34px 0;
    .chat-main {
      width: 970px;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .chat-content {
        width: 100%;
        height: 740px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 0px;
        }

        .chat-content-item {
          display: flex;
          margin-bottom: 20px;
          .chat-head {
            width: 47px;
            height: 47px;
            margin-right: 21px;
          }
        }
        .chat-content-item {
          width: 100%;
          font-size: 14px;
          color: #fff;
          display: flex;
          .user-name {
            margin-right: 21px;
          }
          .user-send-content {
            padding: 14px 20px;
            border-radius: 10px;
            background-color: rgba(34, 77, 127, 0.7);
            line-height: 26px;
          }
          &.is-user-style {
            flex-direction: row-reverse;

            .user-send-content {
              color: #193b61;
              background-color: #fff;
            }
          }
        }
      }
      .dialogue-load {
        width: 90px;
        margin: 0 auto;
      }
    }
  }
}
</style>
